:root {
  --button-color: #ee3d4a;
  --body-text-color: #5d6274;
  --heading-color: #13244d;
  --label-color: #2d313e;
  --v-progress-left: 50px;
  --v-progress-item-height-width: 12px;
  --v-progress-line-height: 35px;
  --v-progress-line-width: 3px;
  --v-progress-gap: 13px;
  --blue: #ee3d4a;
  --green: #159895;
  --light-blue: #ee3d4a;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/Proxima\ Nova\ Font.otf') format('opentype');
}
@font-face {
  font-family: 'Visby CF';
  src: url('./fonts/VisbyCF-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Visby CF';
  src: url('./fonts/VisbyCF-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Visby CF';
  src: url('./fonts/VisbyCF-Medium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Visby CF';
  src: url('./fonts/VisbyCF-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Proxima Nova';
  font-size: 16px;
  line-height: 23px;
}
p {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  font-family: 'Proxima Nova';
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Visby CF';
  font-weight: 600 !important;
}
.mr {
  height: 100vh;
  position: relative;
  overflow-y: scroll;
}
.btn:active {
  border-color: transparent !important;
  outline: none;
}
.btn a {
  font-family: 'Visby CF';
  font-weight: 700;
  text-decoration: none;
  background-color: var(--button-color);
  border-radius: 50px;
  padding: 10px 50px;
  color: #ffff;
  letter-spacing: 1px;
}
.btn a {
  font-family: 'Visby CF';
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  transition: 0.5s ease-out;
}
a {
  color: var(--button-color);
}
.btn:hover a {
  background-color: #9a070f;
}
/* Login Screen */
.mr-login {
  background-image: url('./1_Screenbg-screen1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.mr-bnr {
  background-image: url('./banner.svg');
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  max-width: 767px;
  height: 900px;
  position: relative;
  padding-top: 30px;
}
.mr-bnr::before {
  content: '';
  background-image: url('./banner-white.svg');
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 90%;
  max-width: 767px;
  height: 94%;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.bnr-head img {
  display: block;
  margin: 0 auto 20px;
}
.mr-bnr-cont {
  margin: 20px auto 0;
  text-align: center;
  padding: 20px 50px;
}
.mr-bnr-cont h1 {
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 40px;
  line-height: 80px;
}
.bnr-head img.logo {
  width: 410px;
  margin-bottom: 40px !important;
}
.bnr-head,
.mr-bnr-cont {
  position: relative;
}
.mr-login .form-field input,
.mr-login .form-field select {
  font-size: 15px;
  border-radius: 10px;
  height: 50px;
  padding: 10px 20px;
  font-family: 'Proxima Nova';
  border: 1px solid #000;
  display: block;
  /* width: 500px; */
  width: 100%;
  margin: 0 auto 10px;
  outline: none;
}
.form-elements .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  text-align: left;
}

/* Hide the browser's default checkbox */
.form-elements .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid #ee3d4a;
  border-radius: 5px;
}
.form-field {
  position: relative;
}
/* span.icon {
  position: absolute;
  top: 28px;
  left: 20px;
} */
span.icon {
  position: absolute;
  top: 14px;
  left: 15px;
}
/* .mr-login .form-field.duo-input input,
.mr-login .form-field.duo-input select{

} */
.mr-login .form-field.duo-input .input {
  flex: 0 0 auto;
  width: 48%;
  margin: 0 auto;
  position: relative;
}
/* .mr-login .form-field.duo-input input,
.mr-login .form-field.duo-input select {
  width: 79%;
} */
.mr-login .form-field.duo-input input,
.mr-login .form-field.duo-input select {
  width: 100%;
}
.form-field input {
  padding-left: 45px !important;
}
.mr .form-field input {
  padding-left: 45px !important;
}
/* On mouse-over, add a grey background color */
.form-elements .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.form-elements .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-elements .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-elements .container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #ee3d4a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-elements.login-screen {
  padding: 0 65px;
}
.form-fields label.container {
  margin-top: 30px;
}
/* USER REGISTRATION */
.rmsteps {
  background-image: url('./user_reg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
/* .rmsteps .mr-container {
  margin: 0 auto;
  width: 100%;
  max-width: 80%;
} */
.rmsteps .mr-container {
  margin: 0 auto;
  width: 100%;
  max-width: 767px;
  height: 100%;
}
.inputContainer {
  width: 100%;
  position: relative;
}
.inputContainer label {
  display: block;
  line-height: 19px;
}
.inputContainer input {
  background-color: #ffffff;
  border: 0;
  width: 98%;
  border-radius: 10px;
  height: 45px;
  padding: 5px 20px;
  margin-top: 10px;
  font-family: 'Proxima Nova';
  font-size: 16px;
  transition: 0.3s ease-out;
  outline: none;
  /* border: 5px solid #fff; */
  margin-bottom: 5px;
}
.inputContainer input:focus {
  box-shadow: 0px 0px 5px #01c6f4;
  /* border: 5px solid #01c6f485; */
}
.inputContainer select {
  background-color: #ffffff;
  border: 0;
  width: 99%;
  border-radius: 10px;
  height: 45px;
  padding: 5px 20px;
  margin-top: 10px;
  font-family: 'Proxima Nova';
  font-size: 16px;
  transition: 0.3s ease-out;
  outline: none;
  /* border: 5px solid #fff; */
  margin-bottom: 5px;
}
h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}
.mr-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mr-row .mr-col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.mr-row .mr-col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.mr-row .mr-col-4 {
  flex: 0 0 auto;
  width: 40%;
}
.mr-row .mr-col-3 {
  flex: 0 0 auto;
  width: 30%;
}
.mr-row .mr-col-2 {
  flex: 0 0 auto;
  width: 20%;
}

.mr-row .mr-col-7 {
  flex: 0 0 auto;
  width: 70%;
}
.mr-row .mr-col-8 {
  flex: 0 0 auto;
  width: 80%;
}
span.sub-title {
  font-size: 20px;
  line-height: 25px;
  color: #000;
  font-weight: 600;
  font-family: 'Visby CF';
}
.divider {
  height: 1px;
  background-color: #07b8e2;
  margin: 20px 0;
}
.mr-row .mr-col-12 .inputContainer input {
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input.submit-btn {
  font-family: 'Visby CF';
  font-weight: 700;
  text-decoration: none;
  background-color: var(--button-color);
  border-radius: 50px;
  padding: 15px 0px !important;
  color: #ffff;
  letter-spacing: 1px;
  height: auto;
  margin-left: auto;
  display: block;
}
a.reset-btn {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  margin-top: 15px;
}
a.reset-btn img {
  margin-right: 10px;
}
.ramp_logo {
  padding: 50px 0 0;
  text-align: center;
  margin-bottom: 30px;
}
/* EMAIL CONFIRMATION */
.inputContainer.email-con {
  text-align: center;
  margin: 0 auto;
  display: block;
}
.inputContainer.email-con input {
  text-align: center;
  font-size: 46px;
  height: 150px;
  width: 80px;
}
/* .mr-row .mr-col-2 {
  flex: 0 0 auto;
  width: 15%;
  margin: 0 auto;
} */
.email_confirmation .mr-row .mr-col-2 {
  margin: 0 10px;
  width: 80px;
}
.email_confirmation .mr-row {
  justify-content: center;
}
.mr.rmsteps.email_confirmation input.submit-btn {
  margin: 0 auto;
  width: auto;
  padding: 10px 40px !important;
}
.mr.rmsteps.email_confirmation form {
  margin-top: 80px;
}
.mr-content {
  margin-top: 80px;
  padding: 40px 0;
}
.mr.rmsteps.pillar_css .mr-content {
  margin-top: 40px;
}
/* DASHBOARD */
.main-row > .col-md-6 {
  flex: 0 0 auto;
  width: 49.3%;
  margin: 0 0px;
  padding: 40px;
}
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('./1_Screenbg-screen1.jpg');
  background-position: center center;
}
.app .form_mod {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 30px 50px;
  box-shadow: 0 3px 3px #000000a3;
  width: 600px;
  margin: 0 auto;
  height: 446px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.app .form_mod input {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  margin-bottom: 10px;
  width: 450px;
  color: #e0e0e0;
  box-shadow: 0 3px 3px #cec8c845;
}
.app .form_mod button {
  background-color: #ee3d4a;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  width: 250px;
  padding: 20px 35px;
  border-radius: 50px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 15px;
}
.app.bg-white {
  background-image: unset;
  background-color: #a8e7f7 !important;
  width: 100% !important;
  display: flex;
}
.nav {
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 25px 0 25px;
  overflow: hidden;
}
.nav.ad_specific {
  flex-direction: unset;
}
.nav ul li a {
  background-color: transparent;
  padding: 12px 40px;
  border-radius: 50px;
  text-decoration: none;
  color: #2d313e;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: 'Visby CF';
  font-weight: bold;
  margin-bottom: 10px;
}
.nav ul li.active a,
.nav ul li:hover a {
  background-color: #ee3d4a;
  color: #fff;
}
.nav ul li.active a img,
.nav ul li:hover a img {
  filter: brightness(100);
}

.nav ul {
  display: block;
  list-style: none;
  margin-top: 30px;
  padding: 0;
  text-align: left;
}
.nav ul li a img {
  margin-right: 10px;
}
.main-content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main-content {
  width: 100%;
  height: 100vh;
  padding: 80px 50px 50px;
  overflow-y: scroll;
}
h1.mod-title {
  margin-bottom: 50px;
}
.main-row > .col-md-12,
.main-row > .col-md-6 {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  /* margin-bottom: 20px; */
  box-shadow: 0 3px 3px #00000069;
}
.main-content .col-md-12 {
  height: auto;
}
/* .main-content .col-md-6 {
  height: 280px;
} */
.main-content iframe {
  width: 100%;
  height: 100%;
}
.main-row > div {
  background-color: #fff;
  border-radius: 10px;
  /* margin-right: 10px; */
  box-shadow: 3px 3px 3px #00000047;
}
ul.btm-nav {
  position: absolute;
  bottom: 0;
}
.inner-row {
  padding: 20px;
}
.list-contacts {
  padding-top: 25px;
}
.list-contacts .row {
  padding: 10px 0 0;
}
.app.bg-white.dashboard-emails .main-content {
  justify-content: flex-start;
  padding-top: 130px;
}
.stage-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}
.stage-row {
  background-color: #eff3fa;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  margin: 5px 0;
  position: relative;
  transition: 0.3s ease-out;
}
.stage-row:hover {
  background-color: #d5d9e1;
}
/* KNOBS */

/* .range2 {
   height: 10px;
   bottom: 14px;
   border-radius: 5px;
   background-color: #f1f1f1;
   position: relative;
   width: 0;
}
*/
.volume-bar {
  background-color: #eff3fa;
  height: 4px;
  float: left;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-left: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}
.knob2 {
  text-align: center;
  width: 8px;
  height: 24px;
  position: relative;
  bottom: 10px;
  z-index: 2;
  border-radius: 10px;
  background-color: #ee3d4a;
}
/* END KNOBS */

.volume-bar::before {
  content: '';
  border-radius: 50px;
  height: 12px;
  width: 12px;
  position: absolute;
  left: -15px;
  bottom: -4px;
  background-color: #eff3fa;
  z-index: 1000;
  display: block;
}
.volume-bar::after {
  content: '';
  border-radius: 50px;
  height: 12px;
  width: 12px;
  position: absolute;
  right: -15px;
  bottom: -4px;
  background-color: #eff3fa;
  z-index: 1000;
  display: block;
}
/* EMAIL DETAILS */
button.back-btn {
  background-color: unset;
  border: 0;
}
span.em_stage {
  font-size: 24px;
  line-height: 28px;
  display: inline-block;
}
.em_dots {
  position: absolute;
  right: 15px;
  top: 15px;
}
.em_stage-options {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  position: absolute;
  right: 40px;
  top: 0;
  z-index: 2;
  display: none;
}
.em_stage-options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.em_stage-options ul li a {
  color: #13244d;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}
.em_stage-options ul li:hover a {
  text-decoration: underline;
}
.item-formality {
  margin-bottom: 20px;
}
.col-md-8 {
  width: 65.666667%;
}
.bc_container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  margin: 10px 10px;
}

.barcontainer {
  background-color: #e9ecef;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin-left: 50px;
  width: 13px;
  height: 100%;
  float: left;
  border-radius: 3px;
}

.bar {
  background-color: #ea514e;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
  box-sizing: border-box;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}
@keyframes grow {
  from {
    transform: scaleY(0);
  }
}

/* END EMAIL DETAILS */

/* Ideal Customer */
.c_img {
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  margin-right: 10px;
}
.customer-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-out;
}
.stage-row:hover .c_info {
  text-decoration: underline;
  font-weight: 900;
}
.ideal_customer .em_dots {
  position: absolute;
  right: 15px;
  top: 20px;
}
.view-style {
  margin-left: auto;
  display: block;
  width: 200px;
  padding-bottom: 20px;
  text-align: right;
}
.view-style button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 3px solid #eff3fa;
  background-color: transparent;
}
.view-style button:focus,
.view-style button:hover {
  border-color: #ee3d4a;
}
button.edit_buton {
  background-color: transparent;
  border: 0;
}
.cus_avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
}
span.cus_full_name {
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600 !important;
  padding-top: 15px;
}
.ideal_customer.ideal_cus_details table * {
  font-family: 'Visby CF';
}
/* Ideal Customer */
.bbl-red {
  color: var(--button-color);
}
.fontSize-20 {
  font-size: 20px;
}
.fontSize-12 {
  font-size: 12px;
}
.fontSize-18 {
  font-size: 18px;
}
/* .col-md-4 {
  width: 32.633333%;
} */
table.table.table-bordered {
  border-color: #ee3d4a;
}

.dashboard_content {
  max-height: 800px;
  overflow-y: scroll;
}

/* Components */
.form-field input {
  font-size: 15px;
  border-radius: 10px;
  height: 50px;
  padding: 10px 20px;
  font-family: 'Proxima Nova';
  border: 1px solid #000;
  display: block;
  width: 100%;
  margin: 3px 0 10px;
}
.form-field.duo-input {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}
.customer_align .form-field.duo-input {
  justify-content: center;
}

.form-field input,
.form-field select {
  font-size: 15px;
  border-radius: 10px;
  height: 50px;
  padding: 10px 20px;
  font-family: 'Proxima Nova';
  border: 1px solid #000;
  display: block;
  width: 100%;
  margin: 3px 0 10px;
  outline: none;
}
/* .rmsteps .mr-content center img:not(.btn img) {
  width: 330px;
  margin-bottom: 30px;
} */
.rmsteps .mr-content center img:not(.btn img) {
  width: 280px;
  margin-bottom: 30px;
}
.form-field textarea {
  font-size: 15px;
  border-radius: 10px;
  height: 110px;
  padding: 10px 20px;
  font-family: 'Proxima Nova';
  border: 1px solid #000;
  display: block;
  width: 100%;
  margin: 3px 0 10px;
  outline: none;
}
/* .form-field.duo-input .input.in1 {
  margin-right: 15px;
} */
.form-field input:focus,
.form-field select:focus,
.form-field textarea {
  border-color: #ee3d4a;
}
.form-field.duo-input .input {
  flex: 0 0 auto;
  width: 49%;
}
.customer_align .form-field.duo-input .input {
  flex: 0 0 auto;
  width: 40%;
  margin-bottom: 15px;
}
.customer_seek {
  background-color: #fff;
  position: relative;
  height: 200px;
  overflow: scroll;
  box-shadow: 0 3px 3px #00000029;
}
.btn-red {
  background-color: #ee3d4a;
  border: 0;
  padding: 20px 30px;
  border-radius: 50px;
  color: #fff;
  font-family: 'Visby CF';
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 18px;
  outline: none;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
.btn-red:hover {
  background-color: #ae0b17;
}
.form-fields .btn-red {
  margin: 10px auto;
  display: block;
}
.card-image {
  padding: 40px 20px 20px;
  background-image: linear-gradient(180deg, #3d92c6, #17608d);
  border-radius: 10px;
  box-shadow: 3px 3px 3px #0000002e;
  color: #fff;
  margin-top: 25px;
  text-align: left;
}
.card-image span {
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 23px;
}
#plan-txt span {
  font-size: 18px;
  line-height: 27px;
  font-family: 'Visby CF';
  display: block;
  text-align: left;
}
.acc-image {
  margin: 50px 0 30px;
  text-align: center;
  color: var(--button-color);
  cursor: pointer;
}
.acc-image span {
  padding-left: 20px;
}
.form-field label {
  display: block;
  text-align: initial;
}
/* vertical progress */
.v-progress {
  background-color: var(--body-background-color);
  padding: 10px 0 10px 15px;
  overflow: scroll;
  height: 100%;
}
.v-progress ul {
  list-style: none;
}

.v-progress-item {
  position: relative;
  /* left: var(--v-progress-left); */
  margin-left: var(--v-progress-left);
  height: var(--v-progress-item-height-width);
  line-height: var(--v-progress-item-height-width);
  margin-bottom: var(--v-progress-line-height);
  --v-progress-border: 8px;
}

.v-progress-item:last-child {
  margin-bottom: 0px;
}

.v-progress-item:last-child:after {
  border-left: 0px;
}

.v-progress-item:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: var(--v-progress-item-height-width);
  height: var(--v-progress-item-height-width);
  left: calc(0px - var(--v-progress-left));
  border-radius: 50%;
  background-color: #ccc;
}

.v-progress-item:after {
  content: '';
  display: inline-block;
  position: absolute;
  height: calc(var(--v-progress-line-height) - var(--v-progress-gap));
  top: calc(var(--v-progress-item-height-width) + var(--v-progress-gap) / 2);
  left: calc(0px - var(--v-progress-left) + var(--v-progress-item-height-width) / 2 - var(--v-progress-line-width) / 2);
  border-left: var(--v-progress-line-width) solid #ccc;
}

.v-progress-item.completed:after {
  border-color: var(--light-blue);
}

.v-progress-item.completed:before {
  content: '✔';
  font-size: 11px;
  text-align: center;
  color: white;
  background: var(--light-blue);
  height: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
  width: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
  line-height: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
  left: calc(0px - var(--v-progress-left) - var(--v-progress-border) / 2);
  top: calc(0px - var(--v-progress-border) + var(--v-progress-border) / 2);
}

.v-progress-item.inprogress:before {
  background-color: white;
  /*   height: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
width: calc(var(--v-progress-border) + var(--v-progress-item-height-width)); */
  outline: calc(var(--v-progress-border) / 2) solid var(--blue);
  top: calc(0px - var(--v-progress-border) + var(--v-progress-border));
}
.v-progress span.v-progress-number {
  padding: 5px;
  border: 2px solid #707070;
  color: var(--button-color);
  height: 31px;
  display: inline-block;
  width: 31px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: -10px;
}
li.main-progress {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.radio-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.radio-buttons input[type='radio'] {
  visibility: hidden;
  height: 0;
  width: 0;
  margin-right: 5px;
}

/* .radio-buttons label {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  color: #000000;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  position: relative;
} */
.radio-buttons label {
  display: flex;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  color: #000000;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  position: relative;
  align-items: center;
  gap: 5px;
}
.radio-buttons input[type='radio']:checked + label {
  border-color: #ef3e4c;
}
.grp-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.grp-btns a.btn-red {
  margin: 0 0px 0 10px;
}
a.btn-transparent {
  border: 0;
  padding: 20px 30px;
  border-radius: 50px;
  font-family: 'Visby CF';
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 18px;
  outline: none;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  background-color: transparent;
  color: #626262;
}
/* VIDEOS */
.video-container {
  width: 640px;
  border-radius: 4px;
  margin: 0 auto 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  background-color: #000;
}
.video-container .video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.play-button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  pointer-events: none;
}
.play-button-wrapper #circle-play-b {
  cursor: pointer;
  pointer-events: auto;
}
.play-button-wrapper #circle-play-b svg {
  width: 100px;
  height: 100px;
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0.9;
}
/* END VIDEOS */
/* Progress BAR */
/*progressbar*/
.progress-ramp-bar {
  margin-top: 60px;
}
#progressbar {
  margin-bottom: 30px;

  display: flex;
  counter-reset: step;
  justify-content: center;
  align-items: center;
}
#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  line-height: 20px;
  display: block;
  font-size: 15px;
  color: #ee3d4a;
  background: white;
  border-radius: 50px;
  margin: 0 auto 5px auto;
  font-family: 'Poppins';
  text-align: center;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 2;
  position: relative;
  border: 3px solid #ee3d4a;
}
/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 16px;
  background: #85d8ed;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: 1;
}
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #ee3d4a;
  color: white;
}
#progressbar li span {
  position: absolute;
  z-index: 6;
  top: -50px;
  right: 0;
  left: 0;
  text-align: center;
  font-family: 'Poppins';
  color: #000;
  font-size: 10px;
  font-weight: 600;
}
/* Progress BAR END */

/* primary genders */
.form-field input {
  height: 50px;
  padding: 0px 20px !important;
  width: 100%;
}
.pillar_css .form-field {
  margin-bottom: 20px;
}
.pillar_css .grp-btns {
  margin-top: 40px;
}
/* END primary genders */

/* AGE RANGE */
#slider-div {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: center;
}
#slider-div > div {
  margin: 8px;
}

.slider-label {
  position: absolute;
  background-color: #eee;
  padding: 4px;
  font-size: 0.75rem;
}
.slider-bar {
  flex: 0 0 auto;
  width: 100%;
}
.slider.slider-horizontal {
  width: 100%;
  height: 20px;
}
.slider-handle.round {
  background-color: #ee3d4a !important;
  background-image: unset;
}
.slider-selection {
  background-image: unset !important;
  background-color: #ee3d4a !important;
}
/* AGE RANGE END */
.avatar_picker {
  overflow: scroll;
  height: 500px;
  position: relative;
  width: 800px;
  margin: 0 auto;
}
.avatar_picker ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.avatar_picker ul li {
  flex: 0 0 auto;
  margin: 14px;
}
.avatar_picker ul li a {
  display: block;
}
.avatar_picker ul li a {
  display: block;
  border-radius: 50px;
}
.avatar_picker ul li a {
  border: 3px solid transparent;
  position: relative;
}
.avatar_picker ul li:hover a {
  border-color: #ef5350;
}
.avatar_picker ul li a::before {
  content: '✔';
  border-radius: 50px;
  background-color: #ee3d4a;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  font-size: 25px;
  opacity: 0;
}
.avatar_picker ul li.active a::before {
  opacity: 1;
}
/* WHat customer is Feeling  */
.customer_seek {
  background-color: #fff;
  position: relative;
  height: 200px;
  overflow: scroll;
}
.customer_seek .cs_label {
  background-color: #ef3e4c;
  text-align: center;
  color: #fff;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
}
.customer_seek .cs_content {
  padding: 15px 30px;
  font-size: 13px;
}
.customer_seek.active {
  transform: scale(1.2);
  z-index: 1;
  box-shadow: 3px 3px 3px #0000003d;
  border: 3px solid #59c0ea;
}
/* WHat customer is Feeling  */

/*  RANGE SLIDER FOR SPECTRUM */
.range-slider {
  margin: 60px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
  background-image: linear-gradient(45deg, #3d92c6, #ee3d4a);
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-webkit-slider-thumb:hover {
  background: #fff;
}
.range-slider__range:active::-webkit-slider-thumb {
  background: #fff;
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-moz-range-thumb:hover {
  background: #fff;
}
.range-slider__range:active::-moz-range-thumb {
  background: #fff;
}
.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #59c0ea;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #2c3e50;
  padding: 5px 10px;
  margin-left: 8px;
  display: none;
}
.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #2c3e50;
  border-bottom: 7px solid transparent;
  content: '';
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

.range-slider input::-moz-focus-inner,
.range-slider input::-moz-focus-outer {
  border: 0;
}
.mr.rmsteps.pillar_css.spectrum .mr-container {
  max-width: 767px;
}
.range_slider_level {
  margin: 0 25px;
  width: 200px;
  text-align: center;
}
.range_slider_level {
  margin: 0 25px;
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.range_slider_level img {
  margin: 0 10px;
}
/* END RANGE SLIDER FOR SPECTRUM */

/* Review Screen */
.review_m_stage {
  width: 320px;
  background-color: #fff;
  border: 1px solid #ee3d4a;
  border-radius: 10px;
  margin: 10px;
}
.review_screen_container .mr-container {
  max-width: 90%;
}
.m_title {
  border-bottom: 1px solid #ee3d4a;
  padding: 10px;
  display: flex;
  align-items: center;
}
.b_title {
  color: #59c0ea;
  font-size: 16px;
  font-weight: 800;
  padding: 10px;
  text-align: center;
  font-family: 'Visby CF';
}
.duration {
  text-align: center;
}
.review_cont span.stage_cont {
  color: #ee3d4a;
  font-family: 'Proxima Nova';
  font-size: 25px;
}
.duration {
  text-align: center;
  border-bottom: 1px solid #000000;
  margin: 0 10px 10px;
}
.duration.assets_ {
  text-align: left;

  border-bottom: 0;
}
.reviw_screen_modals {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 40px;
}
.controls {
  display: flex;
  padding-right: 30px;
  justify-content: flex-end;
  align-items: center;
}
.controls a {
  color: #000;
  text-decoration: none;
  margin-left: 15px;
}
.cert_exp {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 3px #00000069;
  width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 10px;
  overflow: hidden;
}
.cert_exp ul {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}
.cert_experts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px;
  height: 380px;
  overflow: scroll;
  /* width: 1400px; */
  width: 767px;
}
.search_partner_Res {
  margin: 0 auto;
  width: 600px;
}
.cert_exp {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 3px #00000069;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 10px;
}
.cert_exp::before {
  content: '';
  position: absolute;
  background-color: #000000c9;
  width: 100%;
  height: 100%;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.cert_exp.hired::before,
.cert_exp:hover::before {
  display: block;
}
.hired_message {
  position: absolute;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-family: 'Visby CF';
  visibility: hidden;
}
.cert_exp.hired .hired_message,
.cert_exp:hover .hired_message {
  visibility: visible;
}
.cert_exp img {
  margin-right: 10px;
}
/* Review Screen End */

/* Partner Hired */
.checkboxOverride input[type='checkbox'] {
  visibility: hidden;
}
.checkboxOverride {
  margin: 20px 10px;
  position: relative;
  width: 25px;
}
.checkboxOverride input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.checkboxOverride label {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #ee3d4a;
  cursor: pointer;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
  color: #ee3d4a;
  border-radius: 5px;
}
.checkboxOverride label:after {
  border-style: none none solid solid;
  content: '';
  height: 5px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari and Chrome */
  transform: rotate(-45deg);
  width: 10px;
}
.share_ramp {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* END PARTNER HIRED */

/* RAMP DOCS */
.table-striped.ramp_docs > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #83e3fc !important;
  color: var(--bs-table-striped-color);
}
ul.pagination {
  margin: 0 auto;
  justify-content: center;
}
ul.pagination li a.page-link {
  border: 0;
  font-family: 'Visby CF';
  color: #000;
  font-weight: 600;
  font-size: 13px;
  width: 23px;
  height: 23px;
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
}
ul.pagination li.active a.page-link {
  background-color: #a8e7f7;
}
ul.pagination li:first-child a,
ul.pagination li:last-child a {
  color: #ee3d4a;
  font-size: 20px;
}
.v-progress-number.inprogress {
  border-color: var(--button-color) !important;
}
.v-progress-number.completed {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
  color: #fff !important;
}
/* RAMP DOCS */
img.btn_icon {
  width: 14px;
  margin: 0 5px;
}
.radio-buttons label::before {
  content: '';
  position: relative;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent !important;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0px 0px 0px 2px #0c1f3b;
}
.radio-buttons input[type='radio']:checked + label::before {
  border-color: #fff;
  background-color: #ee3d4a !important;
  box-shadow: 0px 0px 0px 2px #ee3d4a;
}

/* Add these styles for the progress bars */
.progress-bar-wrapper {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ccc;
  margin-top: 10px;
  border-radius: 5px;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--light-blue); /* Replace with the desired progress bar color */
  border-radius: 5px;
}

/* Responsive */
/* RAMP COMPLETION */
.ramp_completion ul {
  display: block;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.ramp_completion ul li {
  display: flex;
  vertical-align: top;
  padding: 10px;
  background-color: #ffff;
  border: 1px solid #ee3d4a;
  border-radius: 10px;
  box-shadow: 2px 3px 3px #00000042;
  margin: 10px 10px;
  width: 98%;
  height: 125px;
  justify-content: flex-start;
}
.ramp_completion ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.ramp_completion ul li a .ramp_com_icon {
  width: 50px;
  height: 50px;
  background-size: contain;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
}
.ramp_completion ul li a .ramp_com_content h3 {
  margin: 0;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  text-align: left;
  font-weight: 500;
  font-family: 'Visby CF';
  margin-bottom: 5px;
}
.ramp_completion ul li a .ramp_com_content p {
  text-align: left;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}
.ramp_completion_lists {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
/* RAMP COMPLETION END */
/* Pillar 4 Stage 5 */
.p4_delay .checkboxOverride {
  display: flex;
  align-items: center;
}
.p4_delay .checkboxOverride span {
  margin-left: 20px;
  font-size: 16px;
  display: flex;
  gap: 5px;
  line-height: 25px;
}
.p4_delay .checkboxOverride label {
  border-color: #000000;
  background-color: #fff;
}
.p4_delay .checkboxOverride input[type='checkbox']:checked + label {
  border-color: #ee3d4a;
}
.p4_delay .checkboxOverride span select#delay_dur {
  border: 0;
  background-color: transparent;
  color: #ee3d4a;
  border-bottom: 1px solid #ee3d4a;
  outline: none;
}
.pillar_4 {
  background-color: #fff;
  padding: 40px 40px 40px 80px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  border: 1px solid #ee3d4a;
  border-radius: 8px;
  box-shadow: 3px 3px 3px #00000045;
  margin-bottom: 40px;
}
.pillar_4 .p4_delay {
  width: 250px;
}
.p4_type {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
}
.p4_delay.p4_type {
  width: 370px;
}
.p4_type ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  padding: 0;
  list-style: none;
  margin: 11px 0 0;
}
.p4_type ul li {
  flex: 0 0 auto;
  width: 135px;
}
.p4_delay.p4_type .checkboxOverride {
  margin: 7px;
}
.p4_content {
  width: 100%;
  outline: none;
  margin-top: 20px;
}
.list_number {
  background-color: #ee3d4a;
  color: #fff;
  font-family: 'Proxima Nova';
  text-align: left;
  top: 0;
  left: 0;
  width: 80px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
}
.pillar_4_commitment {
  height: 550px;
  overflow-y: scroll;
}
/* PILLAR 4 STAGE 5 */

.cert_details ul li {
  display: flex;
}
.pillar4nextsteps .mr-content img {
  width: auto !important;
}
.rmsteps .mr-content center .cert_experts img:not(.btn img) {
  width: auto !important;
}
.ramp_upsell_checkboxes .checkboxOverride {
  width: 100%;
}
.ramp_upsell_checkboxes {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.rmsteps .mr-content center img:not(.btn img) {
  width: 390px;
}
.dynamic_form_input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}
.dynamic_form_input form.form-horizontal {
  margin: 0 !important;
}
.dynamic_form_input input#textinput {
  width: 600px;
  background-color: #fff;
  border: 2px solid #ee3d4a;
  border-radius: 10px;
  height: 70px;
  padding: 0 30px 0 20px;
  font-size: 15px;
  line-height: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.dynamic_form_input button#add {
  background-color: transparent;
  border: 0;
}
.dynamic_form_input button.delete {
  background-color: transparent;
  color: #fff;
  border: 0;
  padding: 5px;
  border-radius: 5px;
  color: #626262;
  font-family: 'Visby CF';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
}
@media screen and (max-width: 1366px) {
  body {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Proxima Nova';
    font-size: 13px;
    line-height: 20px;
  }
  .mr-bnr {
    max-width: 700px;
    height: 700px;
  }
  .mr-bnr-cont {
    margin: 0px auto 0;
  }
  .v-progress-item.completed:before {
    content: '✔';
    font-size: 7px;
    text-align: center;
    color: white;
  }
  .v-progress-item:after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 15px;
  }
  .v-progress-item {
    position: relative;
    line-height: 12px;
    height: 13px;
    margin-bottom: 25px;
  }
  h1.mod-title {
    margin-bottom: 10px;
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 30px !important;
  }
  .stage-row {
    padding: 10px;
    margin: 0px 0 5px;
  }
  h4 {
    font-size: 18px;
    text-align: -webkit-auto;
  }
}
.mr-bnr {
  max-width: 700px;
  height: 700px;
}
.mr-bnr-cont {
  margin: 0px auto 0;
}
.v-progress-item.completed:before {
  content: '✔';
  font-size: 7px;
  text-align: center;
  color: white;
}
.v-progress-item:after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 15px;
}
.v-progress-item {
  position: relative;
  line-height: 12px;
  height: 13px;
  margin-bottom: 25px;
}
h1.mod-title {
  margin-bottom: 10px;
  font-size: 30px;
}
h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}
.stage-row {
  padding: 10px;
  margin: 0px 0 5px;
}
h4 {
  font-size: 18px;
  text-align: -webkit-auto;
}
.btn-red.plan_upgrage_btn {
}
.btn-red {
  padding: 18px 25px;
  letter-spacing: 0px;
  font-size: 16px;
  display: inline-block;
  margin-top: 20px;
}
#plan-txt span {
  font-size: 15px;
  line-height: 24px;
}
.rmsteps .radio-buttons {
  justify-content: center;
  flex-wrap: wrap;
}
.radio-buttons input[type='checkbox']:checked + label {
  border-color: #ef3e4c;
}
.radio-buttons input[type='checkbox']:checked + label::before {
  border-color: #fff;
  background-color: #ee3d4a !important;
  box-shadow: 0px 0px 0px 2px #ee3d4a;
}
.radio-buttons input[type='checkbox'] {
  visibility: hidden;
  height: 0;
  width: 0;
  margin-right: 5px;
}
.radio-buttons {
  gap: 10px 0;
}
.range-slider > div {
  width: 100% !important;
}
.slider-bar {
  width: 340px !important;
}

.range_slider_level {
  flex: 0 0 auto;
  width: 160px !important;
}
.range-slider > div {
  width: 100% !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  flex-wrap: wrap;
}
.rmsteps .mr-content center img:not(.btn img) {
  width: 280px;
  margin-bottom: 30px;
}
/* .inputContainer {
  margin-bottom: 15px;
  margin-top: 20px;
} */
.inputContainer {
  margin-bottom: 10px;
  margin-top: 10px;
}
.radio-buttons input[type='checkbox'] + label::before {
  border-radius: 5px;
}
.radio-buttons input[type='checkbox']:checked + label::after {
  border-radius: 5px;
}
.radio-buttons input[type='checkbox'] + label::before {
  border: 0;
}
.radio-buttons input[type='checkbox'] + label::after {
  content: '';
  position: absolute;
  left: 12px;
  top: 15px;
  background: white;
  width: 3px;
  height: 3px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
.range_slider_level span {
  width: 145px !important;
  display: inline-block;
}
/* Additional CSS */
.gradient_bbl_white_blue {
  background-image: linear-gradient(-45deg, #05f2db4a, transparent);
}
.main-row {
  gap: 20px;
}
table p {
  text-align: left;
  font-size: inherit;
  line-height: 16px;
}
.buy_the_book {
  padding: 20px 200px;
  align-items: center;
}
.buy_the_book h2 {
  text-align: left;
  font-size: 37px;
  line-height: 51px;
}
.buy_the_book p {
  text-align: left;
}
.buy_the_book .btn {
  display: block;
  margin: 0;
  text-align: left;
  padding: 0;
}
.list-contacts .row {
  align-items: center;
}
.list-contacts .row .col-md-10 {
  text-align: left;
}
.hc ul li {
  text-align: left;
}
.nav > img {
  width: 240px;
}
.customer_seek .cs_label span {
  display: block;
  font-size: 10px;
}
/* Additional CSS */

/* Latest Updates */
.pillar4nextsteps img.img_pillar_four {
  max-width: 967px !important;
  width: 100% !important;
  min-width: 970px !important;
  margin: 0 auto !important;
  margin-left: -100px !important;
}
.rmsteps .inputContainer label {
  text-align: left;
}
img.btn_icon {
  margin-bottom: unset !important;
  width: auto !important;
}
.brand_pitch_gpt .form-fields.customer_align.brand_pitch .input {
  width: 48%;
}
.brand_pitch_gpt .form-field.duo-input {
  flex-wrap: wrap;
}
img.pillar_com_img {
  width: 600px !important;
}
/* Latest Updates End */
/* Secret Sauce */
.secret_sauce .form-fields .form-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}
.secret_sauce .form-fields .form-field .radio-buttons {
  width: 200px;
  justify-content: flex-start;
}
.secret_sauce .form-fields .form-field .radio-buttons label {
  width: 175px;
}
.secret_sauce .form-fields .form-field .ss_desc {
  width: 460px !important;
}
.secret_sauce .form-fields .form-field .ss_desc p {
  padding: 0;
  text-align: left;
  margin: 0 0 25px;
}
.ss_hidden_field {
  display: flex;
}
.rmsteps .secret_sauce .radio-buttons {
  justify-content: center;
  flex-wrap: unset;
}
.secret_sauce .form-fields .form-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}
.ss_hidden_field input {
  width: inherit;
}
.ss_hidden_field {
  flex: 0 0 auto;
  width: 89% !important;
  margin: -10px 0 20px;
}
/* Secret Sauce */
@media screen and (max-width: 1680px) {
  .customer_seek .cs_label {
    font-size: 13px;
    line-height: 16px;
  }
  .mr.rmsteps.email_confirmation .mr-row {
    justify-content: center;
  }
  .mr.rmsteps.email_confirmation .mr-row .mr-col-2 {
    width: 80px;
    margin: 0 10px;
  }
}
@media screen and (max-width: 1366px) {
  .mr.rmsteps.pillar_css.spectrum .mr-container {
    max-width: 80%;
  }
  .slider-bar {
    width: 360px !important;
  }
  .range_slider_level {
    flex: 0 0 auto;
    width: 120px !important;
  }
}
@media screen and (max-width: 1200px) {
  .range_slider_level span {
    width: 90px !important;
  }
}

/* Steps SVG */
.st0 {
  fill: #136189;
}
.st1 {
  fill: #136da0;
}
.st2 {
  fill: #258bbf;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  font-family: 'ProximaNova-Medium';
}
.st5 {
  font-size: 15px;
}
.st6 {
  fill: #3a93ad;
}
.st7 {
  fill: #40accc;
}
.st8 {
  fill: #59c0ea;
}
.st9 {
  fill: #2790ba;
}
.st10 {
  fill: #2c9bd1;
}
.st11 {
  fill: #3eb2e1;
}
/*  Lottie Player  */
lottie-player {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.lottie_container {
  height: 450px;
}
